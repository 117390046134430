<template>
  <div id="Clientes">
    <section class="barra-acciones">
      <BaseSelectCliente @select-cliente="selectCliente" ref="BaseSelectCliente" />
      <BaseButton title="Alta de cliente" buttonType="verde" :disabled="this.formulario.usuario_id"
        @onCta="altaCliente()" />
      <BaseButton title="Guardar" :disabled="!this.formulario.usuario_id" @onCta="modificarCliente()" />
      <BaseButton title="Suspender" buttonType="rojo" :disabled="!this.formulario.usuario_id" />
      <BaseButton title="Limpiar Formulario" buttonType="rojo" @onCta="() => {
        this.limpiarPantalla();
      }
        " />
      <BaseButton title="Administrar Proveedores" :disabled="!this.formulario.usuario_id" @onCta="() => {
        this.$router.push(
          `/app/precios-clientes/${this.formulario.usuario_id}/nombre/${this.formulario.nombre}`
        );
      }
        " />
    </section>
    <div class="contenedor-form">
      <!-- Autenticacion -->
      <div class="separador-container">
        <div class="separador"></div>
        <span>Autenticacion</span>
        <div class="separador"></div>
      </div>
      <b-form-group id="input-correo" description="Correo para iniciar sesion." label="Correo*"
        label-for="input-correo">
        <b-form-input id="input-correo" required v-model="formulario.email" />
      </b-form-group>
      <b-form-group id="input-password" label="Contraseña*" label-for="input-password">
        <b-form-input id="input-password" type="password" v-model="formulario.password" />
      </b-form-group>
      <BaseButton class="btn-password" title="Actualizar Contraseña"
        :disabled="!this.formulario.usuario_id || !this.formulario.password" @onCta="actualizarPassword()" />
      <!-- Datos Generales -->
      <div class="separador-container">
        <div class="separador"></div>
        <span>Datos Generales</span>
        <div class="separador"></div>
      </div>

      <b-form-group id="input-razon-social" description="Nombre al que se factura." label="Razon Social*"
        label-for="input-razon-social">
        <b-form-input id="input-razon-social" v-model="formulario.razon_social" />
      </b-form-group>
      <b-form-group id="input-nombre" description="Nombre del cliente." label="Nombre*" label-for="input-nombre">
        <b-form-input id="input-nombre" v-model="formulario.nombre" />
      </b-form-group>
      <b-form-group id="input-nombre-comercial" label="Nombre Comercial*" label-for="input-nombre-comercial">
        <b-form-input id="input-nombre-comercial" v-model="formulario.perfilCliente.nombre_comercial" />
      </b-form-group>
      <b-form-group id="input-rfc" label="R.F.C.*" label-for="input-rfc">
        <b-form-input id="input-rfc" v-model="formulario.rfc" />
      </b-form-group>
      <b-form-group id="input-domicilio-fiscal" class="full" description="(Calle, Numero, Colonia)"
        label="Domicilio Fiscal*" label-for="input-domicilio-fiscal">
        <b-form-input id="input-domicilio-fiscal" v-model="formulario.domicilio" />
      </b-form-group>
      <b-form-group id="input-codigo-postal" label="Codigo Postal*" label-for="input-codigo-postal">
        <b-form-input id="input-codigo-postal" v-model="formulario.perfilCliente.codigo_postal" />
      </b-form-group>
      <b-form-group id="input-ciudad" label="Ciudad*" label-for="input-ciudad">
        <b-form-input id="input-ciudad" v-model="formulario.perfilCliente.ciudad" />
      </b-form-group>
      <b-form-group id="input-estado" label="Estado*" label-for="input-estado">
        <b-form-input id="input-estado" v-model="formulario.perfilCliente.estado" />
      </b-form-group>
      <b-form-group id="input-tel-1" label="Tel. Trabajo 1*" label-for="input-tel-1" class="wd-50">
        <b-form-input id="input-tel-1" v-model="formulario.tel_1" />
      </b-form-group>
      <b-form-group id="input-tel-2" label="Tel. Trabajo 2" label-for="input-tel-2" class="wd-50">
        <b-form-input id="input-tel-2" v-model="formulario.tel_2" />
      </b-form-group>
      <!-- Datos Propietario -->
      <div class="separador-container">
        <div class="separador"></div>
        <span>Datos Propietario</span>
        <div class="separador"></div>
      </div>
      <b-form-group id="input-propietario" label="Propietario*" class="full"
        description="Nombre del Propietario, Director o Gerente" label-for="input-propietario">
        <b-form-input id="input-propietario" v-model="formulario.contactoPdg.nombre" />
      </b-form-group>
      <b-form-group id="input-movil-pdg" label="Movil P, D, G" label-for="input-movil-pdg">
        <b-form-input id="input-movil-pdg" v-model="formulario.contactoPdg.tel" />
      </b-form-group>
      <b-form-group id="input-correo-pdg" label="Correo P, D, G" label-for="input-correo-pdg">
        <b-form-input id="input-correo-pdg" v-model="formulario.contactoPdg.correo" />
      </b-form-group>
      <b-form-group id="input-nacimiento-pdg" label="Fecha de Nacimiento P, D, G" label-for="input-nacimiento-pdg">
        <b-form-input id="input-nacimiento-pdg" type="date" v-model="formulario.contactoPdg.nacimiento" />
      </b-form-group>
      <!-- Datos Comprador -->
      <div class="separador-container">
        <div class="separador"></div>
        <span>Datos Comprador</span>
        <div class="separador"></div>
      </div>
      <b-form-group id="input-nombre-comprador" label="Nombre del Comprador*" class="full"
        label-for="input-nombre-comprador">
        <b-form-input id="input-nombre-comprador" v-model="formulario.contactoComprador.nombre" />
      </b-form-group>
      <b-form-group id="input-movil-comprador" label="Movil Comprador" label-for="input-movil-comprador">
        <b-form-input id="input-movil-comprador" v-model="formulario.contactoComprador.tel" />
      </b-form-group>
      <b-form-group id="input-correo-comprador" label="Correo Comprador*" label-for="input-correo-comprador">
        <b-form-input id="input-correo-comprador" v-model="formulario.contactoComprador.correo" />
      </b-form-group>
      <b-form-group id="input-nacimiento-comprador" label="Fecha de Nacimiento Comprador"
        label-for="input-nacimiento-comprador">
        <b-form-input id="input-nacimiento-comprador" v-model="formulario.contactoComprador.nacimiento" />
      </b-form-group>
      <!-- Datos Responsable de pagos -->
      <div class="separador-container">
        <div class="separador"></div>
        <span>Datos Responsable de Pagos</span>
        <div class="separador"></div>
      </div>
      <b-form-group id="input-responsable-pagos" label="Nombre del Responsable de Pagos*" class="full"
        label-for="input-responsable-pagos">
        <b-form-input id="input-responsable-pagos" v-model="formulario.contactoPagos.nombre" />
      </b-form-group>
      <b-form-group id="input-movil-pagos" label="Movil de R. Pagos" label-for="input-movil-pagos">
        <b-form-input id="input-movil-pagos" v-model="formulario.contactoPagos.tel" />
      </b-form-group>
      <b-form-group id="input-correo-pagos" label="Correo de R. Pagos" label-for="input-correo-pagos">
        <b-form-input id="input-correo-pagos" v-model="formulario.contactoPagos.correo" />
      </b-form-group>
      <b-form-group id="input-nacimiento-pagos" label="Fecha de Nacimiento R. de Pagos"
        label-for="input-nacimiento-pagos">
        <b-form-input id="input-nacimiento-pagos" v-model="formulario.contactoPagos.nacimiento" />
      </b-form-group>
    </div>
    <div class="contenedor-form-right">
      <!-- Contacto Pago -->
      <div class="separador-container">
        <div class="separador"></div>
        <span>Contacto Pago</span>
        <div class="separador"></div>
      </div>
      <b-form-group id="input-correo-facturas" label="Correo Para Envio de Facturas*" label-for="input-correo-facturas">
        <b-form-input id="input-correo-facturas" v-model="formulario.perfilCliente.correo_facturas" />
      </b-form-group>
      <b-form-group id="input-correo-pedidos" label="Correo Para Confirmacion de Pedidos*"
        label-for="input-correo-pedidos">
        <b-form-input id="input-correo-pedidos" v-model="formulario.perfilCliente.correo_pedidos" />
      </b-form-group>
      <b-form-group id="input-correo-ofertas" label="Correo Para Envio de Ofertas*" label-for="input-correo-ofertas">
        <b-form-input id="input-correo-ofertas" v-model="formulario.perfilCliente.correo_ofertas" />
      </b-form-group>
      <b-form-group id="input-correo-notificaciones" label="Correo Para Envio de Notificaciones*"
        label-for="input-correo-notificaciones" class="wd-50">
        <b-form-input id="input-correo-notificaciones" v-model="formulario.perfilCliente.correo_notificaciones" />
      </b-form-group>
      <b-form-group id="input-intervalo-notificaciones" label="Intervalo Para Envio de Notificaciones*"
        label-for="input-intervalo-notificaciones" class="wd-50">
        <b-form-input id="input-intervalo-notificaciones" v-model="formulario.perfilCliente.intervalo_notificaciones"
          type="number"></b-form-input>
      </b-form-group>
      <b-form-group id="input-condiciones-venta" label="Condiciones de Venta*" label-for="input-condiciones-venta"
        class="wd-50">
        <b-form-select id="input-condiciones-venta" v-model="formulario.perfilCliente.condiciones_venta" :options="[
          'Contra entrega',
          'Pago por adelantado',
          'Credito 15 Dias',
          'Credito 30 Dias',
          'Credito 45 Dias',
          'Credito 60 Dias',
        ]"></b-form-select>
      </b-form-group>
      <b-form-group id="input-dias-prorroga" label="Dias de Prorroga*" label-for="input-dias-prorroga" class="wd-50">
        <b-form-input id="input-dias-prorroga" v-model="formulario.perfilCliente.dias_prorroga" type="number" />
      </b-form-group>
      <!-- Temporadas -->
      <div class="separador-container">
        <div class="separador"></div>
        <span>Temporadas</span>
        <div class="separador"></div>
      </div>
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio-group id="radio-group-1" v-model="formulario.perfilCliente.temporadaActiva" :options="[
          'Temporada Navideña',
          'Temporada Escolar',
          'Temporada Oficina',
        ]" :aria-describedby="ariaDescribedby"></b-form-radio-group>
      </b-form-group>
      <b-form-group class="full" id="input-temporada-termina" label="Temporada Temina"
        label-for="checkbox-temporada-termina">
        <b-form-input id="input-temporada-termina" v-model="formulario.temporada_termina" type="date" />
      </b-form-group>
      <!-- Metodos Pago -->
      <div class="separador-container">
        <div class="separador"></div>
        <span>Metodos Pago</span>
        <div class="separador"></div>
      </div>
      <b-form-group id="input-metodo-pago" label="Metodo Pago*" label-for="input-metodo-pago" class="wd-50">
        <b-form-select id="input-metodo-pago" v-model="formulario.metodos_pago" :options="metodosPago"></b-form-select>
      </b-form-group>
      <b-form-group id="input-forma-pago" label="Forma Pago*" label-for="input-forma-pago" class="wd-50">
        <b-form-select id="input-forma-pago" v-model="formulario.forma_pago" :options="[
          'Opcion 1',
          'Opcion 2',
          'Opcion 3',
          'Opcion 4',
          'Opcion 5',
        ]"></b-form-select>
      </b-form-group>
      <b-form-group id="input-regimen-fiscal" label="Regimen Fiscal*" label-for="input-regimen-fiscal" class="wd-50">
        <b-form-select id="input-regimen-fiscal" v-model="formulario.regimen_fiscal" :options="[
          'Actividad Empresarial',
          'Arrendamiento',
          'Asalariado',
          'Intereses',
          'Servicios Profesionales',
          'Incorporacion Fiscal',
        ]"></b-form-select>
      </b-form-group>
      <b-form-group id="input-uso-cfdi" label="Uso del CFDI*" label-for="input-uso-cfdi" class="wd-50">
        <b-form-select id="input-uso-cfdi" v-model="formulario.uso_cfdi" :options="usoCfdi"></b-form-select>
      </b-form-group>
      <!-- Componente para Fotos -->
      <div class="separador-container">
        <div class="separador"></div>
        <span>Componente para Fotos</span>
        <div class="separador"></div>
      </div>
      <DocumentosClientes class="full" :perfilCliente="formulario.perfilCliente" />
      <!-- Registros -->
      <div class="separador-container fecha-container wd-50">
        <div class="separador"></div>
        <span>Registros</span>
        <div class="separador"></div>
      </div>
      <div class="info-container fecha-container wd-50">
        <span>Fecha de Alta</span>
        <p>{{ parseTime(formulario.created_at) || "-" }}</p>
      </div>
      <div class="info-container fecha-container wd-50">
        <span>Fecha de Ultima Edicion</span>
        <p>{{ parseTime(formulario.updated_at) || "-" }}</p>
      </div>
      <div class="info-container fecha-container wd-50">
        <span>Usuario que dio de Alta</span>
        <p>{{ formulario.created_by || "-" }}</p>
      </div>
      <div class="info-container fecha-container wd-50">
        <span>Usuario ultima Edicion</span>
        <p>{{ formulario.updated_by || "-" }}</p>
      </div>
      <!-- <BaseButton class="full" buttonType="verde" title="Informacion" /> -->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import api from "../../../../utils/api";
import notification from '../../../../utils/notification';
import DocumentosClientes from "./DocumentosClientes.vue";
export default {
  name: "Clientes",
  components: {
    DocumentosClientes,
  },
  data() {
    return {
      personal_id: null,
      usoCfdi: [],
      metodosPago: [],
      formulario: {
        email: null,
        password: null,
        nombre: null,
        apellido: "-",
        tel: null,
        domicilio: null,
        celular: 0,
        curp: "N/A",
        no_seguro: "N/A",
        nacimiento: null,
        email_personal: null,
        esCliente: true,
        razon_social: null,
        id_original: null,
        ciudad: null,
        rfc: null,
        tel_2: null,
        tel_3: null,
        comprador: null,
        condiciones: null,
        cumpleanos_comprador: null,
        clave: null,
        cobro_email: null,
        cobro_dias: null,
        tel_1: null,
        updated_at: null,
        updated_by: null,
        created_at: null,
        created_by: null,
        perfilCliente: {
          id: null,
          nombre_comercial: null,
          codigo_postal: null,
          ciudad: null,
          estado: null,
          correo_facturas: null,
          correo_comprador: null,
          correo_pagos: null,
          correo_confirmacion: null,
          correo_ofertas: null,
          correo_notificaciones: null,
          intervalo_notificaciones: null,
          condiciones_venta: null,
          dias_prorroga: null,
          metodo_pago: null,
          forma_pago: null,
          regimen_fiscal: null,
          uso_cfdi: null,
        },
        contactoPdg: {
          id: null,
          puesto: "PDG",
          correo: null,
          tel: null,
          nombre: null,
          nacimiento: null,
        },
        contactoComprador: {
          id: null,
          puesto: "comprador",
          correo: null,
          tel: null,
          nombre: null,
          nacimiento: null,
        },
        contactoPagos: {
          id: null,
          puesto: "pagos",
          correo: null,
          tel: null,
          nombre: null,
          nacimiento: null,
        },
      },
      initialState: {},
      show: true,
      cargando: true,
    };
  },
  mounted() {
    this.initialState = this.$data.formulario;
    this.consultarUsosCfdi();
    this.consultarMetodosPago();
  },
  methods: {
    ...mapActions("clientes", ["listarClientesSelect"]),
    async selectCliente(id) {
      if (id) {
        this.cargando = true;
        const { error, cliente } = await this.consultarDetallesCliente(id);
        if (error) {
          this.handleError("Error al consultar al cliente");
          return;
        }
        this.formulario = { ...cliente };
        this.cargando = false;
      }
      if (id === null) this.limpiarPantalla();
    },
    consultarDetallesCliente(id) {
      return api("GET", `usuarios/consultar/${id}`)
        .then((result) => {
          if (result.errorCode) {
            return { error: true };
          }
          return { cliente: result.usuario };
        })
        .catch(() => {
          return { error: true };
        });
    },
    handleError(errorMsg = "Ha ocurrido un error!") {
      this.limpiarPantalla();
      this.$notify({
        group: "foo",
        title: "Error",
        text: errorMsg,
        type: "error",
      });
      this.cargando = false;
    },
    parseTime(val) {
      const x = new Date(val);
      return moment(x).format("L").toString();
    },
    consultarUsosCfdi() {
      this.cargando = true;
      api("GET", "uso-cfdi/consultar-uso-cfdis")
        .then((result) => {
          this.usoCfdi = result.usosCfdi.map(
            ({ codigo, nombre, uso_cfdi_id }) => {
              return { value: uso_cfdi_id, text: `${codigo} - ${nombre}` };
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    consultarMetodosPago() {
      this.cargando = true;
      api("GET", "metodo-pago/consultar-metodos")
        .then((result) => {
          this.metodosPago = result.metodosPago.map(
            ({ metodo, metodo_pago_id }) => {
              return { value: metodo_pago_id, text: metodo };
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    limpiarPantalla() {
      this.limpiarSelector();
      this.formulario = this.initialState;
      this.clienteSeleccionado = null;
    },
    limpiarSelector() {
      const child = this.$refs.BaseSelectCliente;
      child.limpiarSeleccion();
    },
    actualizarPassword() {
      api("PUT", "usuarios/actualizar-password/" + this.formulario.usuario_id, {
        password: this.formulario.password,
      }).then(({ passwordActualizada }) => {
        let notification = {
          group: "foo",
          title: "Error",
          text: "La contrasena no se actualizo",
          type: "error",
        };
        if (passwordActualizada) {
          notification = {
            group: "foo",
            title: "Success",
            text: "Contrasena Actualizada!",
            type: "success",
          };
        }
        this.$notify(notification);
      });
      return;
    },
    async altaCliente() {
      let notification = {
        group: "foo",
        title: "Error al crear el cliente",
        text: "Ingrese todos los campos indicados con *",
        type: "error",
      };
      api("POST", "usuarios/crear-cliente", {
        personal_id: localStorage.getItem("usuario_id"),
        ...this.formulario,
        codigo_postal: this.formulario.perfilCliente.codigo_postal,
      })
        .then(async (result) => {
          if (result.clienteCreado) {
            await this.selectCliente(result.clienteCreado);
            await this.listarClientesSelect();
            this.$notify({
              group: "foo",
              title: "Cliente Creado!",
              text: "Cliente creado con exito",
              type: "success",
            });
            return;
          }
          if (result.errorCode === 18772) {
            this.$notify({
              group: "foo",
              title: "Error al crear el cliente",
              text: "El cliente ya se encuentra registrado",
              type: "error",
            });
            return;
          }
          if (result.errorCode || result === "error")
            this.$notify(notification);
        })
        .catch(() => {
          this.$notify(notification);
        });
    },
    modificarCliente() {
      if (this.formulario.contactoComprador.tel?.length > 13) {
        this.$notify({
          group: 'foo',
          title: 'Error al modificar al cliente',
          text: 'Los números telefónicos deben ser menores de 13 caracteres',
          type: 'warning'
        })
        return;
      }

      api("PUT", `usuarios/${this.formulario.usuario_id}/cliente`, {
        personal_id: localStorage.getItem("usuario_id"),
        cliente: {
          ...this.formulario,
          nacimiento: this.formulario.nacimiento ? moment(this.formulario.nacimiento.split('T')[0]).format('YYYY-MM-DD') : null,
        },
      })
        .then(() => {
          notification('success', 'Cliente', 'El cliente ha sido actualizado', 3000);
          this.selectCliente(this.formulario.usuario_id);
        })
        .catch((error) => {
          notification('error', 'Cliente', error.response.data.msg || 'Hubo un problema al actualizar.', 3000);
        });
    },
  },
};
</script>
<style lang="scss" src="./Clientes.scss" />