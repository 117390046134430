<template>
  <div id="DocumentosClientes">
    <div class="input-bar">
      <b-form-file
        v-model="file"
        :state="Boolean(file)"
        placeholder="Seleccione o arrastre un archivo..."
        drop-placeholder="Arrastre el archivo..."
        accept=".jpg, .png, .gif, .pdf, .xml"
      />
      <b-form-input
        placeholder="Nombre documento"
        id="input-nombre-documento"
        v-model="nombre"
      />
      <BaseButton title="Guardar Archivo" @onCta="guardarDocumento" />
    </div>
    <div class="documents_wrapper" v-if="documentos && documentos.length !== 0">
      <ul>
        <li v-for="(element, index) in documentos" :key="index">
          <div class="document_container">
            <template v-if="element.path.includes('pdf')">
              <b-icon
                class="icon"
                @click="consultarDocumento(element)"
                style="width: 40px; height: 40px"
                icon="file-earmark-pdf"
              ></b-icon>
            </template>
            <template>
              <b-icon
                class="icon"
                v-if="!element.path.includes('pdf')"
                @click="consultarDocumento(element)"
                icon="file-earmark-image"
                style="width: 40px; height: 40px"
              ></b-icon>
            </template>
            <span class="titulo-archivo">{{ element.nombre }}</span>
            <span class="titulo-eliminar" @click="eliminarDocumento(element)"
              >eliminar</span
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import api from "../../../../utils/api";
import axios from "axios";
export default {
  name: "DocumentosClientes",
  props: {
    defaultProp: {
      type: String,
      default: "Default Prop",
    },
    perfilCliente: {
      type: String,
      default: null,
    },
  },
  components: {},
  data() {
    return {
      documentos: [],
      file: null,
      nombre: "",
    };
  },
  mounted() {
    this.obtenerDocumentos(this.perfilCliente.id);
  },
  destroyed() {},
  methods: {
    guardarDocumento() {
      const payload = new FormData();
      payload.append("documento", this.file);
      payload.append("nombre", this.nombre);

      payload.append("perfil_cliente", this.perfilCliente.id);
      api("POST", "cliente/guardar-documento", payload).then((result) => {
        if (result.body.documentoGuardado) {
          this.$notify({
            group: "foo",
            title: "Documento guardado",
            text:
              "El documento: " + this.nombre + " ha sido guardado con exito.",
            type: "success",
          });
        }
        this.obtenerDocumentos(this.perfilCliente.id);
      });
    },
    obtenerDocumentos(id) {
      api("GET", "cliente/consultar-documentos/" + id)
        .then((result) => {
          if (result.errorCode) {
            this.documentos = [];
            return;
          }
          this.documentos = result.body.documentos;
        })
        .catch(() => (this.documentos = []));
    },
    consultarDocumento(element) {
      const options = {
        method: "POST",
        url: process.env.VUE_APP_JPR_API_URL + "cliente/consultar-documento",
        headers: { "Content-Type": "application/json" },
        responseType: "arraybuffer",
        data: { file: element.path },
      };

      axios
        .request(options)
        .then(function (response) {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers["content-type"],
            })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            element.nombre + "." + element.path.split(".")[1]
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    eliminarDocumento({ id, path }) {
      if (confirm("Eliminar documento?")) {
        api("DELETE", `cliente/eliminar-documento/${path}/${id}`)
          .then((result) => {
            this.obtenerDocumentos(this.perfilCliente.id);
          })
          .catch(() => this.obtenerDocumentos(this.perfilCliente.id));
      }
    },
  },
  computed: {},
  watch: {
    perfilCliente: function ({ id }) {
      this.obtenerDocumentos(id);
    },
  },
};
</script>
<style lang="scss">
#DocumentosClientes {
  display: flex;
  flex-direction: column;
  > .input-bar {
    align-items: center;
    width: 100%;
    > input {
      height: 32px !important;
    }
    button {
      width: 150px !important;
      height: 32px !important;
      font-size: 14px;
    }
  }
}
</style>